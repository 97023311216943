import React from 'react';
import { Row, Col } from 'reactstrap';
import { CONTENT_CREATIVE_IDS } from 'site-modules/shared/constants/editorial/sell-car';
import { CmsEntities } from 'client/data/models/cms';

const transformContent = contentData => {
  const item1Title = contentData.metadata('item-1-title').value();
  const item2Title = contentData.metadata('item-2-title').value();
  const item3Title = contentData.metadata('item-3-title').value();

  return [
    {
      id: item1Title,
      iconSrc: contentData.metadata('item-1-image').value(),
      iconAlt: item1Title,
      title: item1Title,
      text: contentData.metadata('item-1-text').value(),
    },
    {
      id: item2Title,
      iconSrc: contentData.metadata('item-2-image').value(),
      iconAlt: item2Title,
      title: item2Title,
      text: contentData.metadata('item-2-text').value(),
    },
    {
      id: item3Title,
      iconSrc: contentData.metadata('item-3-image').value(),
      iconAlt: item3Title,
      title: item3Title,
      text: contentData.metadata('item-3-text').value(),
    },
  ];
};

export function HowItWorks({ content }) {
  if (!content.hasMetadata('item-1-title')) {
    return null;
  }

  const items = transformContent(content);

  return (
    <section className="how-it-works" data-tracking-parent={CONTENT_CREATIVE_IDS.HOW_IT_WORKS}>
      <h2 className="heading-label heading-2">{content.title}</h2>
      <Row className="mt-2 mt-md-1_5">
        {items.map(({ id, iconSrc, iconAlt, title, text }) => (
          <Col xs={12} md={4} className="px-1_5 px-md-0_5" key={`how-it-works-${id}`}>
            <div className="how-it-works-cards text-center">
              <img src={iconSrc} width={141} height={141} alt={iconAlt} className="d-block mx-auto" loading="lazy" />
              <h3 className="heading-label heading-5 mt-1_5 mt-md-2 mb-0 fw-bold text-cool-gray-40">{title}</h3>
              <p className="mb-1_5 mb-md-1">{text}</p>
            </div>
          </Col>
        ))}
      </Row>
    </section>
  );
}

HowItWorks.propTypes = {
  content: CmsEntities.Content.isRequired,
};

HowItWorks.defaultProps = {};
