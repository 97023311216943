// eslint-disable-next-line venom/no-restricted-classname
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GlobalNavigation } from 'site-modules/shared/components/global-navigation-v2/global-navigation';
import { Footer } from 'site-modules/shared/components/footer/footer';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { SpaLinks } from 'site-modules/shared/components/spa-links/spa-links';
import { ProfileContextAsync } from 'site-modules/shared/components/profile/profile-context-async';
import { ExperimentsPanel } from 'site-modules/shared/components/experiment/panel/experiments-panel';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { ErrorPageMessage } from 'site-modules/shared/components/error-page-message/error-page-message';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { VisitorModel } from 'client/data/models/visitor';
import { SEOHead } from 'site-modules/shared/pages/seo';
import { SkipToMainContent } from 'site-modules/shared/components/skip-to-main-content/skip-to-main-content';

export class EdmundsNoAdsDecoratorUI extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    isMobile: false,
  };

  static preload(store) {
    const preloader = new ModelPreloader(store);
    preloader.resolve('segment', VisitorModel);
    return preloader.load();
  }

  render() {
    return (
      <div className={classnames('edm-page has-global-nav', { desktop: !this.props.isMobile })}>
        <ErrorBoundary>
          <SkipToMainContent />
          <GlobalNavigation showSearchBar />
        </ErrorBoundary>
        <ErrorBoundary>
          <SEOHead {...this.props} />
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="testSpa">
            <SpaLinks />
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary message={ErrorPageMessage}>{this.props.children}</ErrorBoundary>
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="insider">
            <ProfileContextAsync />
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary>
          <ClientSideRender>
            <ExperimentsPanel />
          </ClientSideRender>
        </ErrorBoundary>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const EdmundsNoAdsDecorator = connect(mapStateToProps)(EdmundsNoAdsDecoratorUI);
